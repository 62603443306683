<template>
  <div class="w-full text-center">
    <h1>You are not authorized to visit this page</h1>
    <homepage-bottom-toolbar></homepage-bottom-toolbar>
  </div>
</template>

<script>
  import HomepageBottomToolbar from '@/components/layout/HomepageBottomToolbar';

  export default {
    name: 'NotAuthorized',
    components: { HomepageBottomToolbar },
  };
</script>
